import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import '../../App.css';
import './Welcome.css';

const Welcome = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="container-fluid">
                <div className='row'>
                    <h3>{t('Welcome.confirmacion')}</h3>
                </div>
                <div className='row center container-message'>
                    <img src='/images/sucess.svg' alt=''></img>
                    <h4>{t('Welcome.mensaje1')}</h4>
                    <p>{t('Welcome.mensaje2')}</p>
                </div>
                <div className='row footer'>
                    <a href={`https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar,%20vender%20o%20poner%20en%20renta%20por%20medio%20de%20Monplaces....`} target='_blank' rel='noopener noreferrer'>
                        {t('Common.contactanos')}&nbsp;&nbsp;
                        <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                    </a>
                </div>
            </div>
        </>
    )
}

export default withRouter(Welcome);