import React from 'react';
import { useTranslation } from 'react-i18next';

import './News.css';

const News = ({ items }) => {
    const { t } = useTranslation();

    return (
        <div className='news-container cards'>
            {items.map(item => (
                <div className='card-item' key={item.id}>
                    <div className='image'>
                        <img src={item.publisher_logo_url} alt={item.publisher_name}></img>
                    </div>
                    <div className='header'>
                        <p>{item.header}</p>
                    </div>
                    <div className='link'>
                        <a href={item.link} target='_blank'>{t('Common.leerMas')}</a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default News;