import React from 'react';
import { useQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';

import '../App.css';

const LIST_UNIT = gql`
  query GetUnitByUnitTypeId($unitTypeId: ID!) {
    unitType(id: $unitTypeId) {
      units {
        id
        number
        code
        gallery_urls {
            url
            thumbnailUrl
            type
            tag
        }
        price
        status
      }
    }
  }
`

const ListUnit = (props) => {
    const { loading, data, error } = useQuery(LIST_UNIT, { variables: { unitTypeId: Number(props.match.params.unitTypeId) } })

    if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <div className="col-sm-12">
            {!loading &&
                data.unitType.units.map(unit => (
                    <Link className="col-sm-4" key={unit.id} to={`/developments/${props.match.params.developmentId}/unittypes/${props.match.params.unitTypeId}/units/${unit.id}`}>
                        <div className='pa3 bg-black-05 ma3'>
                            <div
                                style={{
                                    backgroundImage: `url(${unit.gallery_urls.filter(i => i.tag === "layout")[0].url})`,
                                    backgroundSize: 'cover',
                                    paddingBottom: '100%',
                                }}
                            />
                            <div>
                                <div className='unit'>
                                    <h3 align="center"> {unit.number}&nbsp; </h3>
                                    <h4 align="center">Price: ${unit.price}</h4>
                                    <h4 align="center" style={{ textTransform: 'uppercase' }}>{unit.status}</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
        </div>
    );
};

export default withRouter(ListUnit);