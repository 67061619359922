import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import auth from '../../Auth';

import { currencyFormat, concatAddress, toKebab, getAmenityIcon } from '../../utils.js';
import '../../App.css';
import './CheckoutForm.css';

const { REACT_APP_API_URL, REACT_APP_MONPLACE_CUSTOMER_DEAL_API, REACT_APP_MONPLACE_MAIL_UNIT_RESERVE_API } = process.env;

const UNIT = gql`
  query GetUnitById($code: String, $unitId: ID!) {
    unit(code: $code, id: $unitId) {
      id
      code
      number
      floor
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      bedrooms
      bathrooms
      parking_spots
      price
      currency
      size
      status
      operationType
      is_public
      unitType {
        id
        name
        display_name
        gallery_urls {
          url
          thumbnailUrl
          type
          tag
        }
        is_public
        type
      }
      group {
        id
        name
        display_name
        amenities
        is_public    
        address {
          line_1
          line_2
          city
          state
          country
          zip_code
          location
        }
      }
    }
  }
`

const COMMERCIALPOLICY = gql`
  query CommercialPolicy($commercialPolicyId: ID!) {
    commercialPolicy(id: $commercialPolicyId) {
      id
      name
      display_name
      discount
      initialPayment
      partialPayments
      lastPayment
    }
  }
`

const RESERVE_UNIT = gql`
  mutation ReserveUnit($unitId: ID!){
    reserveUnit(id: $unitId){
      id
      code
      price
      status
      operationType
    }
  }
`

const CheckoutForm = (props) => {
    const { t } = useTranslation();

    const [reserveUnit] = useMutation(RESERVE_UNIT);
    const { loading, data, error } = useQuery(UNIT, {
        variables: {
            code: props.match.params.code ? props.match.params.code : "",
            unitId: props.match.params.unitId ? Number(props.match.params.unitId) : 0
        }
    })
    const [loadCommercialPolicy] = useLazyQuery(COMMERCIALPOLICY)

    const [commercialPolicy, setCommercialPolicy] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.log('Stripe.js has not yet loaded.');
            return;
        }

        if (!acceptTerms || !acceptPrivacy) {
            console.log('Need to accepts Terms and Privacy.');
            return;
        }

        const { error: stripeError, token } = await stripe.createToken(elements.getElement(CardElement));

        if (stripeError) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(stripeError.message);
            return;
        }

        const userInfo = await auth.getUserInfo();

        await createDeal(userInfo, data.unit, commercialPolicy);

        await fetch(
            `${REACT_APP_API_URL}/authorize-payment`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    currency: 'mxn',
                    email: userInfo.email,
                    given_name: userInfo.given_name,
                    family_name: userInfo.family_name,
                    source: token.id,
                    unitId: data.unit.id,
                    unitCode: data.unit.code,
                }),
            }
        )
            .then(response => response.json())
            .then(charge => {
                console.log(charge);

                if (charge.status === 'succeeded') {
                    reserveUnit({
                        variables: {
                            code: props.match.params.code ? props.match.params.code : "",
                            unitId: props.match.params.unitId ? Number(props.match.params.unitId) : 0
                        }
                    })
                        .then((_) => {
                            emailNotification(userInfo, data.unit, commercialPolicy, charge.id);
                            props.history.push(`/checkout/${props.match.params.unitId}/complete`);
                        })
                        .catch((error) => {
                            console.error(error.message);
                            props.history.push(`/checkout/${props.match.params.unitId}/failed`);
                        });
                } else {
                    console.error(charge);
                    props.history.push(`/checkout/${props.match.params.unitId}/failed`);
                }
            })
            .catch((error) => {
                console.error(error.message);
                props.history.push(`/checkout/${props.match.params.unitId}/failed`);
            });

        // const { error: backendError, clientSecret } = await fetch(
        //     `${REACT_APP_API_URL}/create-payment-intent`,
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({

        //             paymentMethodType: 'card',
        //             currency: 'mxn',
        //         }),
        //     }
        // ).then((result) => result.json());

        // if (backendError) {
        //     console.log(backendError.message);
        //     return;
        // }

        // console.log('Client secret returned');

        // const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        //     clientSecret,
        //     {
        //         payment_method: {
        //             card: elements.getElement(CardElement),
        //             billing_details: {
        //                 name: `${userInfo.given_name} ${userInfo.family_name}`,
        //             },
        //         },
        //     }
        // );

        // if (stripeError) {
        //     // Show error to your customer (e.g., insufficient funds)
        //     console.log(stripeError.message);
        //     return;
        // }

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        // console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

        // if (paymentIntent.status === 'succeeded') {
        //     props.history.push(`/checkout/${props.match.params.unitId}/complete`);
        // }

        // console.log(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

        // if (paymentIntent.status === 'succeeded') {
        //     props.history.push(`/checkout/${props.match.params.unitId}/complete`);
        // }
    };

    const createDeal = async (user, unit, commercialPolicy) => {
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                contact: {
                    email: user.email,
                    firstName: user.given_name,
                    lastName: user.family_name,
                },
                deal: {
                    transactionType: unit.operationType,
                    product: unit.group.display_name,
                    unit: unit.code,
                    amount: commercialPolicy ? (unit.price - (unit.price * commercialPolicy.discount / 100)) : unit.price
                }
            })
        }

        await fetch(REACT_APP_MONPLACE_CUSTOMER_DEAL_API, requestOptions)
            .then(response => {
                if (response.ok) {
                    console.log("Negocio creado en CRM")
                }
                else {
                    console.log("¡Ocurrió un error!, al crear negocio en CRM")
                }
            })
            .catch(error => console.log(`¡Ocurrió un error!, ${error}`));
    }

    const emailNotification = async (user, unit, commercialPolicy, chargeId) => {
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: user.email,
                developmentName: unit.group.display_name,
                unitType: unit.unitType.display_name,
                unitNumber: unit.number,
                unitCode: unit.code,
                unitPrice: commercialPolicy ? (unit.price - (unit.price * commercialPolicy.discount / 100)) : unit.price,
                unitImageUrl: unit.unitType.gallery_urls.filter(i => i.tag === 'layout')[0].url,
                chargeId: chargeId
            })
        }

        await fetch(REACT_APP_MONPLACE_MAIL_UNIT_RESERVE_API, requestOptions)
            .then(response => {
                if (response.ok) {
                    console.log("Email de reserva enviado")
                }
                else {
                    console.log("¡Ocurrió un error!, al enviar email de reserva")
                }
            })
            .catch(error => console.log(`¡Ocurrió un error!, ${error}`));
    }

    const calculatePartialPrice = (price, discount, partial) => {
        return (price - (price * discount / 100)) * partial / 100;
    }

    useEffect(() => {
        if (data) {
            if (props.match.params.commercialPolicyId) {
                loadCommercialPolicy({ variables: { commercialPolicyId: Number(props.match.params.commercialPolicyId) } }).then((response) => {
                    if (response.data.commercialPolicy) {
                        setCommercialPolicy(response.data.commercialPolicy)
                    }
                })
            } else {
                setCommercialPolicy(null)
            }
        }
    }, [loading, data, props, loadCommercialPolicy]);

    if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
    if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="container-fluid">
                <div style={{ margin: '0 20px' }}>
                    <div className='row'>
                        <h3><Link className='prefix-icon icon-arrow-left' to={`/developments/${data.unit.group.address.location}/${data.unit.group.name}/${data.unit.unitType.name}/${data.unit.code}`}>Detalles del pago</Link></h3>
                    </div>
                    <div className='row'>
                        <div className='development-info'>
                            {data.unit.group.is_public &&
                                <h4>Desarrollo: {data.unit.group.display_name}</h4>
                            }
                            {!data.unit.group.is_public &&
                                <h4>Código: {data.unit.code}</h4>
                            }
                            <p className="prefix-icon icon-location" style={{ margin: '0' }}>{concatAddress(data.unit.group.address)}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='checkout-unit-container'>
                            <h4>Tu unidad</h4>
                            <div className='card-item'>
                                <div style={{ padding: '20px' }}>
                                    {data.unit.unitType.is_public &&
                                        <p className='title' style={{ fontWeight: 'normal' }}>Tipo: {data.unit.unitType.display_name}</p>
                                    }
                                    <div>
                                        <img src={data.unit.unitType.gallery_urls.filter(i => i.tag === 'layout')[0].url} alt={data.unit.code} style={{ position: 'center', width: '100%', height: 'auto', padding: '20px 0' }}></img>
                                    </div>
                                    <p>Código: {data.unit.code}</p>
                                </div>
                                <div className='info outer' style={{ textAlign: 'left', padding: '5px 20px', border: 'none' }}>
                                    <div className='middle'>
                                        <p className="subtitle">{currencyFormat(data.unit.price, data.unit.currency)}</p>
                                        <p className="prefix-icon icon-land-size">{data.unit.size} m2</p>
                                        <p className="prefix-icon icon-bedroom">{data.unit.bedrooms} rec</p>
                                        {data.unit.bathrooms === 1 &&
                                            <p className="prefix-icon icon-bathroom">1 baño</p>
                                        }
                                        {data.unit.bathrooms !== 1 &&
                                            <p className="prefix-icon icon-bathroom">{data.unit.bathrooms} baños</p>
                                        }
                                        {data.unit.parking_spots === 1 &&
                                            <p className="prefix-icon icon-car nowrap">1 cajón</p>
                                        }
                                        {data.unit.parking_spots !== 1 &&
                                            <p className="prefix-icon icon-car nowrap">{data.unit.parking_spots} cajones</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='amenities-container'>
                            <h4>Amenidades y servicios del desarrollo</h4>
                            <div className='amenities'>
                                {data.unit.group.amenities.map(amenity => (
                                    <div key={amenity}>
                                        <p className='prefix-icon' style={{
                                            backgroundImage: `url(${getAmenityIcon(amenity)})`,
                                            backgroundSize: '30px 30px',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                            {amenity}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {commercialPolicy &&
                        <div className='row'>
                            <div className='amenities-container'>
                                <h4>Política comercial: {commercialPolicy.display_name}</h4>
                                <div>
                                    <div>
                                        <span>Subtotal </span>
                                        <span style={{ float: 'right' }}>{currencyFormat(data.unit.price, data.unit.currency)}</span>
                                    </div>

                                    <div>
                                        <span>Descuento: {commercialPolicy.discount}%</span>
                                        <span style={{ float: 'right' }}>(-{currencyFormat(data.unit.price * commercialPolicy.discount / 100, data.unit.currency)})</span>
                                    </div>

                                    <div>
                                        <span>Pago inicial: {commercialPolicy.initialPayment}%</span>
                                        <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, commercialPolicy.discount, commercialPolicy.initialPayment), data.unit.currency)}</span>
                                    </div>

                                    <div>
                                        <span>Mensualidades: {commercialPolicy.partialPayments}%</span>
                                        <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, commercialPolicy.discount, commercialPolicy.partialPayments), data.unit.currency)}</span>
                                    </div>

                                    <div>
                                        <span>Pago final: {commercialPolicy.lastPayment}%</span>
                                        <span style={{ float: 'right' }}>{currencyFormat(calculatePartialPrice(data.unit.price, commercialPolicy.discount, commercialPolicy.lastPayment), data.unit.currency)}</span>
                                    </div>

                                    <div style={{ borderTop: '1px solid #000', marginTop: '10px' }}>
                                        <span>TOTAL </span>
                                        <span style={{ float: 'right' }}>{currencyFormat((data.unit.price - (data.unit.price * commercialPolicy.discount / 100)), data.unit.currency)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='payment-container'>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                <div className='left' style={{ marginBottom: '20px' }}>
                                    <h4>Pagar con</h4>
                                </div>
                                <div className='right'>
                                    <img style={{ width: '30px', height: 'auto' }} src='/images/tc-visa.svg' alt='visa'></img>
                                    <img style={{ width: '30px', height: 'auto' }} src='/images/tc-mastercard.svg' alt='mastercard'></img>
                                    <img style={{ width: '30px', height: 'auto' }} src='/images/tc-amex.svg' alt='amex'></img>
                                </div>
                            </div>
                            <div className='payment-info'>
                                <div>
                                    <form id="payment-form" onSubmit={handleSubmit}>
                                        <CardElement id="card" />
                                    </form>
                                </div>
                                <div style={{ margin: '0 auto', width: '300px' }}>
                                    <p>Detalles del apartado</p>
                                    <div>
                                        <span>Apartado de la unidad</span>
                                        <span style={{ float: 'right' }}>{currencyFormat(5000)}</span>
                                    </div>
                                    <div>
                                        <span>TOTAL </span>
                                        <span style={{ float: 'right' }}>{currencyFormat(5000)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='policies-container'>
                            <h4>Política de cancelación</h4>
                            <p>
                                Al apartar tu unidad, ésta queda reservada a tu nombre por 7 días naturales. Conservamos todas las características que escogiste de tipo, nivel y precio mientras tomas la decisión. El apartado es 100% reembolsable dentro de los primeros 7 días naturales (168 hrs.) del proceso de compra.&nbsp;
                                <Link style={{ fontWeight: '600' }} to="/terms" target="_blank">Más información.</Link>
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='submit-container'>
                            <div>
                                <input onChange={(e) => { setAcceptTerms(e.target.checked) }} type="checkbox" />Acepto términos y condiciones
                            </div>
                            <div>
                                <input onChange={(e) => { setAcceptPrivacy(e.target.checked) }} type="checkbox" />Acepto políticas de privacidad
                            </div>
                            <div className='right mobile-hide'>
                                <button type="submit" form="payment-form" className="btn btn-principal" disabled={!stripe || !acceptTerms || !acceptPrivacy}>Confirmar y pagar</button>
                            </div>
                            <div className='center mobile-display' style={{ margin: '20px auto' }}>
                                <button type="submit" form="payment-form" className="btn btn-principal" disabled={!stripe || !acceptTerms || !acceptPrivacy}>Confirmar y pagar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(CheckoutForm);