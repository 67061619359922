import React, { useEffect } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Nav from './components/Nav';
import GuardedRoute from './components/GuardedRoute';
import Callback from './components/Callback';
import ListUnitType from './components/ListUnitType';
import ListUnit from './components/ListUnit';
import Search from './components/Search';

import Home from './pages/Home/Home';
import ListDevelopment from './pages/ListDevelopment/ListDevelopment';
import ListOpportunityUnit from './pages/ListOpportunityUnit/ListOpportunityUnit';
import DetailDevelopment from './pages/DetailDevelopment/DetailDevelopment';
import DetailUnitType from './pages/DetailUnitType/DetailUnitType';
import DetailUnit from './pages/DetailUnit/DetailUnit';
import DetailOpportunityUnit from './pages/DetailOpportunityUnit/DetailOpportunityUnit';
import CheckoutForm from './pages/CheckoutForm/CheckoutForm';
import CheckoutComplete from './pages/CheckoutComplete/CheckoutComplete';
import CheckoutFailed from './pages/CheckoutFailed/CheckoutFailed';
import Privacy from './pages/Compliance/Privacy';
import Terms from './pages/Compliance/Terms';
import Welcome from './pages/Welcome/Welcome';

// import CreateDevelopment from './components/CreateDevelopment';
// import CreateUnitType from './pages/CreateUnitType/CreateUnitType';
// import CreateUnit from './pages/CreateUnit/CreateUnit';

import './App.css';
import auth from './Auth';



const App = (props) => {
  useEffect(() => {
    const authCallback = async () => {
      if (props.location.pathname === '/callback') return;
      try {
        await auth.silentAuth();
        // forceUpdate();
      } catch (err) {
        if (err.error === 'login_required') return;
        console.log(err.error);
      }
    }

    authCallback();
  }, [props])

  return (
    <div>
      <Nav />
      <Switch>
        <Route exact path='/' component={Home} />

        <Route exact path='/developments' component={ListDevelopment} />
        <Route exact path='/developments/:developmentId' component={DetailDevelopment} />
        <Route exact path='/developments/:developmentId/unittypes' component={ListUnitType} />
        <Route exact path='/developments/:developmentId/unittypes/:unitTypeId' component={DetailUnitType} />
        <Route exact path='/developments/:developmentId/unittypes/:unitTypeId/units' component={ListUnit} />
        <Route exact path='/developments/:developmentId/unittypes/:unitTypeId/units/:unitId' component={DetailUnit} />
        <Route exact path='/developments/:location/:development' component={DetailDevelopment} />
        <Route exact path='/developments/:location/:development/:model' component={DetailUnitType} />
        <Route exact path='/developments/:location/:development/:model/:code' component={DetailUnit} />

        <Route exact path='/properties' component={ListOpportunityUnit} />
        <Route exact path='/properties/:operation/:type/:location' component={ListOpportunityUnit} />
        <Route exact path='/properties/:unitId' component={DetailOpportunityUnit} />
        <Route exact path='/properties/:operation/:type/:location/:code' component={DetailOpportunityUnit} />

        <Route exact path='/callback' component={Callback} />
        <GuardedRoute exact path='/checkout/:unitId' component={CheckoutForm} />
        <GuardedRoute exact path='/checkout/:unitId/policy/:commercialPolicyId' component={CheckoutForm} />
        <GuardedRoute exact path='/checkout/:unitId/complete' component={CheckoutComplete} />
        <GuardedRoute exact path='/checkout/:unitId/failed' component={CheckoutFailed} />
        <GuardedRoute exact path='/welcome' component={Welcome} />
        <Route exact path='/search' component={Search} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/terms' component={Terms} />

        <Route exact path='/:operation/:type' component={ListDevelopment} />
        <Route exact path='/:operation/:type/:location' component={ListDevelopment} />
        <Route exact path='/:operation/:type/:location/:development' component={DetailDevelopment} />
        <Route exact path='/:operation/:type/:location/:development/:model' component={DetailUnitType} />
        <Route exact path='/:operation/:type/:location/:development/:model/:code' component={DetailUnit} />

        {/* <GuardedRoute exact path='/admin/developers/create' component={CreateDeveloper} /> */}
        {/* <GuardedRoute exact path='/admin/developments/create' component={CreateDevelopment} /> */}
        {/* <GuardedRoute exact path='/admin/unittypes/create' component={CreateUnitType} roles='admin' /> */}
        {/* <GuardedRoute exact path='/admin/units/create' component={CreateUnit} roles='admin' /> */}
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default withRouter(App);