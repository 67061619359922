import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import './ImageGalleryVideo.css';

export default class ImageGalleryVideo extends React.Component {
    constructor(props) {
        super(props);
        // the reference to the container
        this.ref = React.createRef();

        this.state = {
            items: [],
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showGalleryPlayButton: true,
            showVideo: {},
        };

        this.images = this.props.items.map(item => {
            if (item.type === 'video') {
                return {
                    original: item.url,
                    thumbnail: item.thumbnailUrl,
                    originalClass: item.originalClass,
                    bulletClass: item.bulletClass,
                    thumbnailClass: item.thumbnailClass,
                    renderItem: this._renderVideo.bind(this)
                }
            }
            else {
                return {
                    original: item.url,
                    thumbnail: item.thumbnailUrl ?? item.url,
                    originalClass: item.originalClass,
                    bulletClass: item.bulletClass,
                    thumbnailClass: item.thumbnailClass
                }
            }
        })
    }

    // _onImageClick(event) {
    //     console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
    // }

    // _onImageLoad(event) {
    //     console.debug('loaded image', event.target.src);
    // }

    // _onSlide(index) {
    //     this._resetVideo();
    //     console.debug('slid to index', index);
    // }

    // _onPause(index) {
    //     console.debug('paused on index', index);
    // }

    // _onScreenChange(fullScreenElement) {
    //     console.debug('isFullScreen?', !!fullScreenElement);
    // }

    // _onPlay(index) {
    //     console.debug('playing from index', index);
    // }

    _resetVideo() {
        this.setState({ showVideo: {} });

        if (this.state.showPlayButton) {
            this.setState({ showGalleryPlayButton: true });
        }

        if (this.state.showFullscreenButton) {
            this.setState({ showGalleryFullscreenButton: true });
        }
    }

    _toggleShowVideo(url) {
        const showVideo = this.state;
        this.setState({
            showVideo: {
                ...showVideo,
                [url]: !showVideo[url]
            }
        });

        if (!showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({ showGalleryPlayButton: false });
            }

            if (this.state.showFullscreenButton) {
                this.setState({ showGalleryFullscreenButton: false });
            }
        }
    }

    _renderVideo(item) {
        return (
            <div className='image-gallery-image'>
                <div className='video-wrapper'>
                    <video autoPlay loop muted>
                        <source src={item.original} type='video/mp4' />
                    </video>
                </div>
            </div>
        );
    }

    render() {
        return (
            <ImageGallery
                ref={i => this._imageGallery = i}
                items={this.images}
                // onClick={this._onImageClick.bind(this)}
                // onImageLoad={this._onImageLoad}
                // onSlide={this._onSlide.bind(this)}
                // onPause={this._onPause.bind(this)}
                // onScreenChange={this._onScreenChange.bind(this)}
                // onPlay={this._onPlay.bind(this)}
                onClick={this.props.onClick}
                startIndex={this.props.startIndex}
                showBullets={this.props.showBullets}
                showFullscreenButton={this.props.showFullscreenButton && this.state.showGalleryFullscreenButton}
                showPlayButton={this.props.showPlayButton && this.state.showGalleryPlayButton}
                showThumbnails={this.props.showThumbnails}
                additionalClass={this.props.additionalClass}
            />
        );
    }
}
