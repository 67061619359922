import React from 'react';
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import ReactGA from 'react-ga4';
import { track as trackAmplitude } from '@amplitude/analytics-browser';

import auth from '../../Auth';

import './AuthLinkDownload.css';

const AuthLinkDownload = ({ page, action, fileUrl, fileName, className = 'btn-auth-link-download', style = {}, children }) => {
    const location = useLocation();

    const eventTrack = (category, action, label) => {
        console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });

        trackAmplitude(category, {
            action: action,
            label: label
        });
    }

    const handleClick = async (event) => {
        event.preventDefault();

        eventTrack(page, action, "Link");

        if (!auth.isAuthenticated()) {
            return auth.login(location.pathname);
        }

        saveAs(fileUrl, fileName);
    }

    return (
        <button className={className} style={style} onClick={handleClick}>
            {children}
        </button>
    )
}

export default AuthLinkDownload;