import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import '../../App.css';
import './CheckoutFailed.css';

const CheckoutFailed = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="container-fluid">
                <div className='row'>
                    <h3><Link className='prefix-icon icon-arrow-left' to={`/checkout/${props.match.params.unitId}`}>Confirmación de pago</Link></h3>
                </div>
                <div className='row center container-message'>
                    <img src='/images/failure.svg' alt=''></img>
                    <h4>Tu pago no se ha procesado, por favor intenta nuevamente.</h4>
                    <p>
                        Si estás teniendo problemas para realizar el pago,&nbsp;
                        <a style={{ color: '#4DAAB8', textDecoration: 'underline' }} href={`https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar%20en%20Monplaces....`} target='_blank' rel='noopener noreferrer'>
                            ponte en contacto con nostros.
                        </a>
                    </p>
                </div>
                <div className='row footer'>
                    <a href={`https://api.whatsapp.com/send?phone=523318904974&text=%C2%A1Hola!%20Me%20interesa%20comprar,%20vender%20o%20poner%20en%20renta%20por%20medio%20de%20Monplaces....`} target='_blank' rel='noopener noreferrer'>
                        ¿Tienes dudas? Contáctanos por WhatsApp&nbsp;&nbsp;
                        <img style={{ width: '36px', height: 'auto' }} src='/images/whatsapp.svg' alt='Whatsapp logo'></img>
                    </a>
                </div>
            </div>
        </>
    )
}

export default withRouter(CheckoutFailed);