import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import HubspotForm from 'react-hubspot-form'

import '../App.css';

const Search = (props) => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="container-fluid" style={{ marginTop: '90px' }}>
                <div className="row">
                    <div className="col">
                        <HubspotForm
                            portalId='21025615'
                            formId='91fc8c7c-bbf0-48c6-8ed4-018fe23868b0'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Search);