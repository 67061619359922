import React from 'react';
import H from "@here/maps-api-for-javascript";
import onResize from 'simple-element-resize-detector';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        // the reference to the container
        this.ref = React.createRef();
        // reference to the map
        this.map = null;
    }

    componentDidMount() {
        const {
            lat,
            lng,
            zoom
        } = this.props;

        if (!this.map) {
            // instantiate a platform, default layers and a map as usual
            const platform = new H.service.Platform({
                apikey: 'l-zc7x-OI-k5ZMh3lWXjXyT2jAzH_OS6lqQZwAEXj7Y'
            });
            const layers = platform.createDefaultLayers();
            const map = new H.Map(
                this.ref.current,
                layers.vector.normal.map,
                {
                    pixelRatio: window.devicePixelRatio,
                    center: { lat: lat, lng: lng },
                    zoom: zoom,
                },
            );

            var icon = new H.map.Icon("/images/marker.svg", { size: { w: 52, h: 56 } });
            var marker = new H.map.Marker({ lat: lat, lng: lng }, { icon: icon });
            map.addObject(marker);

            onResize(this.ref.current, () => {
                map.getViewPort().resize();
            });

            var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            this.map = map;
        }
    }

    componentDidUpdate() {
        const {
            lat,
            lng,
            zoom
        } = this.props;

        if (this.map) {
            // prevent the unnecessary map updates by debouncing the
            // setZoom and setCenter calls
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                var icon = new H.map.Icon("/images/marker.svg", { size: { w: 52, h: 56 } });
                var marker = new H.map.Marker({ lat: lat, lng: lng }, { icon: icon });

                this.map.setZoom(zoom);
                this.map.addObject(marker);
                this.map.setCenter({ lat, lng });
            }, 100);
        }
    }

    render() {
        return (
            <div
                style={{ position: 'relative', width: '100%', height: '300px' }}
                ref={this.ref}
            />
        )
    }
}
