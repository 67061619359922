import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import loading from '../loading.svg';
import auth from '../Auth';

const { REACT_APP_AUTH0_DOMAIN } = process.env;

class Callback extends Component {

    async componentDidMount() {
        // const firstLoggedInFlag = 'firstLoggedIn';
        const backUrlFlag = 'backUrl';

        const search = this.props.location.search;
        const state = new URLSearchParams(search).get('state');

        if (state) {
            // const firstLogin = new URLSearchParams(search).get(firstLoggedInFlag);
            // if (firstLogin) {
            //     localStorage.setItem(firstLoggedInFlag, JSON.stringify(true));
            // }
            // else {
            //     localStorage.removeItem(firstLoggedInFlag);
            // }

            window.location.href = `https://${REACT_APP_AUTH0_DOMAIN}/continue?state=${state}`
            return null;
        }

        await auth.handleAuthentication();

        // const redirectUri = JSON.parse(localStorage.getItem(firstLoggedInFlag)) ? '/welcome' : '/';
        // localStorage.removeItem(firstLoggedInFlag);

        const redirectUri = JSON.parse(localStorage.getItem(backUrlFlag)) || '/';
        this.props.history.replace(redirectUri);
    }

    render() {
        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
        }

        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex, follow" />
                </Helmet>
                <div style={style}>
                    <img src={loading} alt="loading" />
                </div>
            </>
        );
    }
}

export default withRouter(Callback);