import auth0 from 'auth0-js';

const { REACT_APP_RETURN_TO, REACT_APP_CALLBACK_URL, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_AUDIENCE } = process.env;

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: REACT_APP_AUTH0_DOMAIN,
            clientID: REACT_APP_AUTH0_CLIENT_ID,
            redirectUri: REACT_APP_CALLBACK_URL,
            audience: REACT_APP_AUTH0_AUDIENCE,
            responseType: 'token id_token',
            scope: 'openid email profile'
        });

        this.authFlag = 'isLoggedIn';
        this.backUrlFlag = 'backUrl';
        this.tokenFlag = 'token';
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    login(path) {
        localStorage.setItem(this.backUrlFlag, JSON.stringify(path || '/'));

        this.auth0.authorize();
    }

    getIdToken() {
        return this.idToken;
    }

    getAccessToken() {
        return this.accessToken;
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err) return reject(err);
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }

                this.setSession(authResult);
                resolve();
            });
        })
    }

    setSession(authResult) {
        this.idToken = authResult.idToken;
        this.accessToken = authResult.accessToken;
        localStorage.setItem(this.tokenFlag, authResult.idToken);
        localStorage.setItem(this.authFlag, JSON.stringify(true));
    }

    logout() {
        localStorage.setItem(this.backUrlFlag, JSON.stringify('/'));
        localStorage.setItem(this.authFlag, JSON.stringify(false));

        this.auth0.logout({
            returnTo: REACT_APP_RETURN_TO,
            clientID: REACT_APP_AUTH0_CLIENT_ID,
        });
    }

    silentAuth() {
        if (this.isAuthenticated()) {
            return new Promise((resolve, reject) => {
                this.auth0.checkSession({}, (err, authResult) => {
                    if (err) {
                        localStorage.removeItem(this.authFlag);
                        return reject(err);
                    }
                    this.setSession(authResult);
                    resolve();
                });
            });
        }
    }

    isAuthenticated() {
        return JSON.parse(localStorage.getItem(this.authFlag));
    }

    getUserInfo() {
        // userinfo
        return new Promise((resolve, reject) => {
            this.auth0.client.userInfo(this.getAccessToken(), (err, user) => {
                if (err) {
                    return reject(err);
                }

                console.log(user);
                resolve(user);
            });
        });
    }
}

const auth = new Auth();

export default auth;