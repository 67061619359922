import React from 'react';
import { useQuery, gql } from "@apollo/client";
import '../App.css';
import { withRouter, Link } from 'react-router-dom';

const LIST_UNIT_TYPES = gql`
  query GetUnitTypesByDevelopmentId($groupId: ID!) {
    group(id: $groupId) {
      unitTypes {
        id
        name
        display_name
        gallery_urls {
          url
          thumbnailUrl
          type
          tag
        }
        type
        units {
          id
        }
      }
    }
  }
`

const ListUnitType = (props) => {
  const { loading, data, error } = useQuery(LIST_UNIT_TYPES, { variables: { groupId: Number(props.match.params.developmentId) } })

  if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
  if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

  return (
    <div className="container-fluid">
      {!loading &&
        data.group.unitTypes.map(unitType => (
          <Link className="col-sm-4" key={unitType.id} to={`/developments/${props.match.params.developmentId}/unittypes/${unitType.id}/units`}>
            <div className='pa3 bg-black-05 ma3'>
              <div
                style={{
                  backgroundImage: `url(${unitType.gallery_urls.filter(i => i.tag === 'layout')[0].url})`,
                  backgroundSize: 'cover',
                  paddingBottom: '100%',
                }}
              />
              <div>
                <div className='unit'>
                  <h3 align="center"> {unitType.display_name}&nbsp; </h3>
                  <h4 align="center">Type: {unitType.type} </h4>
                </div>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default withRouter(ListUnitType);