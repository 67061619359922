import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    Navigation: {
                        "desarrollos": "Developments",
                        "blog": "Blog",
                        "privacidad": "Privacy policiy",
                        "terminos": "Terms of use",
                    },
                    Welcome: {
                        "confirmacion": "Registration completed",
                        "mensaje1": "Congrats, your are now a member of monplaces!",
                        "mensaje2": "You will receive soon news and deals that may be of your interest.",
                    },
                    Home: {
                        "solicitaCreditoHipotecario": 'Apply for a mortgage!',
                        "encuentraTuHogar": 'Find your new home!',
                        "simplificamosOperaciones": "Simplify the way you buy, sell, and rent properties all in one place.",
                        "nosotrosTeAyudamos": "Find your place and reserve it, we help you with a simple but secure process.",
                        "seleccionaTipoPropiedad": "Select the property type",
                        "reservaTuPropiedad": "Reserve",
                        "reservaTuPropiedadBold": "your house or apartment,",
                        "reservaTuPropiedadHighlight": "with $5,000 MXN!",
                        "monplacesSelections": "Monplaces Selections",
                        "explorarMas": "See More",
                        "comoFunciona": "How it works?",
                        "comoFuncionaPaso1": "We identify what you want and your needs to find a match for your next property.",
                        "comoFuncionaPaso2": "There is open and continuos communication to clarify any doubt or concern on your side via phone, WhatsApp or email.",
                        "comoFuncionaPaso3": "If you prefer personal advice, our brokers can provide the information you need in less 15 min by scheduling a video call.",
                        "comoFuncionaPaso4": "Paying with your credit or debit card, reserve with $5,000 MXN!",
                        "comoFuncionaPaso5": "Forget the tedious physical paperwork, we gather all the documentation in digital format to ease the process.",
                        "comoFuncionaPaso6": "Either in person or digital, whatever if better for you!",
                        "comoFuncionaPaso7": "Monplaces will be with you until your property is delivered and more, we keep in touch always.",
                        "comoFuncionaPaso1Brief": "We identify your necessities",
                        "comoFuncionaPaso2Brief": "Keep in touch via WhatsApp",
                        "comoFuncionaPaso3Brief": "Virtual meets across the entire process",
                        "comoFuncionaPaso4Brief": "Reserve your property!",
                        "comoFuncionaPaso5Brief": "Upload your documents digitally",
                        "comoFuncionaPaso6Brief": "Schedule to sign your contract",
                        "comoFuncionaPaso7Brief": "Done!",
                        "hablarConExperto": "Do you want to chat with an expert?",
                        "hablarConExpertoDescripcion": "At Monplaces we care that you have a simple, smooth and secure process. Do not hesitate to schedule a virtual meeting with our experts",
                        "aqui": "here",
                        "encuentraTuEspacio": "Find your place at monplaces",
                        "creditoHipotecario": "Mortgage",
                        "creditoHipotecarioDescripcion": "We have the best partners to get your mortgage",
                        "pagoTarjeta": "Pay with credit or debit card",
                        "pagoTarjetaDescripcion": "To make it simpler, reserve your property with your credit or debit card. It is simple, fast and secure.",
                        "personalizado": "Customized",
                        "personalizadoDescripcion": "If there is not what you are looking for? Contact us and we will help you!",
                        "iniciaProceso": "Start you process here",
                    },
                    DevelopmentList: {
                        "title": "Developments listed | Monplaces",
                        "selecciona": "Select the features you are looking for",
                        "propiedades_one": "1 property listed in monplaces",
                        "propiedades_other": "{{count}} properties listed in monplaces",
                        "otrasUnidades": "More units available in this development",
                        "explorar": "See more",
                        "desdeEnUbicacion": "Starting at {{price}} in {{city}}, {{state}}",
                        "proximamente": "Comming soon",
                        "registrate": "Register here",
                        "listaCero": "Get access to the Friends&Family prices. The moment to invest is now!",
                        "proyectoVendido": "Sold out",
                    },
                    DevelopmentDetails: {
                        "detallesDesarrollo": "Development details",
                        "tipoUnidades": "Model of units",
                        "amenidades": "Amenities and services",
                        "disclosure": "*The features and prices may vary based on the floor, view and other factors. The units and price are subject to change after check availability and updated price list with the developer, so it may be different than the informations published in this site.",
                    },
                    UnitDetails: {
                        "detallesUnidad": "Unit details",
                        "caracteristicas": "Features of the unit",
                        "amenidades": "Amenities and services",
                        "reserva": "Reserve it paying $5,000 MXN",
                        "reembolsable": "100% refundable. T&C may apply.",
                        "disclosure": "*The availability, price and commercial policies are subject to change after check availability and updated price list with the developer, so it may be different than the informations published in this site.",
                    },
                    Checkout: {
                        "detallesPago": "Payment details",
                        "tuUnidad": "Yuor unit",
                        "amenidades": "Amenities and services",
                        "pagarCon": "Pay with",
                        "numeroTarjeta": "Card number",
                        "codigoSeguridad": "CVV",
                        "expiracion": "Expiration date",
                        "politicaCancelacion": "Cancelation policy",
                        "politicaCancelacionDescripcion": "By reserving a unit, this will be reserved for you for the follwing 7 days. We keep the features, type, floor and price during these period of time so you can take your decision. The amount you paid is 100% refundable with in the first 7 days (168 hrs.) after you started the process.",
                        "masInformacion": "More info",
                        "aceptoTerminos": "I agree with terms and conditions",
                        "aceptoPrivacidad": "I agree with privacy policies",
                        "confirmacionPago": "Payment completed",
                        "confirmacionPagoExito": "Congrats, your payment has been proccessed!",
                        "confirmacionPagoExitoDescripcion": "We are validating the information, you will receive soon an email with the confirmation of your unit reservation.",
                        "siguientesPasos": "To move forward with the process of buying your property, please send us your documentation.",
                        "confirmacionPagoError": "Payment was not completed, please try again.",
                        "confirmacionPagoErrorDescripcion": "If you have any issues to complete the payment, ",
                        "confirmacionPagoErrorContacto": "get in contact with us.",
                    },
                    Common: {
                        "loading": "Loading...",
                        "estacionamiento_one": "1 parking spot",
                        "estacionamiento_other": "{{count}} parking spots",
                        "bano_one": "1 bathroom",
                        "bano_other": "{{count}} bathroom",
                        "recamara_one": "1 bedroom",
                        "recamara_other": "{{count}} bedrooms",
                        "codigo": "Code",
                        "ubicacion": "Location",
                        "tipoPropiedad": "Property type",
                        "tipoOperacion": "Operation type",
                        "antiguedad": "Age",
                        "precio": "Price",
                        "superficie": "Size in m2",
                        "medida": "Size",
                        "recamaras": "Bedrooms",
                        "nivel": "Level",
                        "estacionamientos": "Parking spots",
                        "banos": "Bathrooms",
                        "minimo": "Minimum",
                        "maximo": "Maximum",
                        "precioInicial": "Starting at {{price}}",
                        "tamano": "From {{minSize}}m2 to {{maxSize}}m2",
                        "entrega": "Deliver",
                        "inmediata": "Immediate",
                        "tipo": "Type",
                        "descargarBrochure": "Download brochure",
                        "descargarPrecios": "Download price listing",
                        "descargarPoliticas": "Download commercial policies",
                        "desarrolladora": "Developer",
                        "mapa": "Map",
                        "contactanos": "Do you have questions? Chat with us on WhatsApp",
                        "unidades_one": "1 unit available",
                        "unidades_other": "{{count}} units available",
                        "seleccione": "Select…",
                        "mostrarFotos": "Show all photos",
                        "total": "Total",
                        "subtotal": "Subtotal",
                        "seleccionaPolitica": "Select commercial policy",
                        "leerMas": "Read more...",
                        "vendido": "{{percentage}}% sold",
                        "descuento": "Up to {{percentage}}% off",
                    },
                    Buttons: {
                        "buscar": "Search",
                        "reservar": "Reserve",
                        "registrarse": "Sign up",
                        "ingresar": "Sign in",
                        "salir": "Log out",
                        "contactanos": "Contact us",
                        "videollamada": "Book a video call",
                        "cotizacion": "Request a quote",
                        "confirmar": "Confirm & Pay",
                    },
                    Copy: {
                        "copyright": "All rights reserved. The total or partial reproduction of the content of this website is not allowed."
                    },
                    OperationType: {
                        "preventa": "Pre-sale",
                        "compra": "Buy",
                        "renta": "Rent",
                    },
                    PropertyType: {
                        "departamento": "Apartment",
                        "casa": "House",
                        "terreno": "Land",
                    },
                    Age: {
                        "preventa": "Pre-sale",
                        "nuevo": "New",
                    },
                    Price: {
                        '0-2.5': '0 to 2.5 mdp',
                        '2.5-4': '2.5 to 4 mdp',
                        '4-6': '4 to 6 mdp',
                        '6+': 'more than 6 mdp',
                    }
                },
            },
            es: {
                translation: {
                    Navigation: {
                        "desarrollos": "Desarrollos",
                        "blog": "Blog",
                        "privacidad": "Políticas de privacidad",
                        "terminos": "Términos y condiciones",
                    },
                    Welcome: {
                        "confirmacion": "Confirmación del registro",
                        "mensaje1": "¡Felicidades, ya formas parte de monplaces!",
                        "mensaje2": "Pronto recibirás noticias que pueden ser de tu interés, oportunidades de compra y preferencias.",
                    },
                    Home: {
                        "solicitaCreditoHipotecario": "¡Solicita tu crédito hipotecario!",
                        "encuentraTuHogar": '¡Encuentra tu próximo hogar!',
                        "simplificamosOperaciones": "Simplificamos la compra, venta y renta de propiedades, ¡en un sólo lugar!",
                        "nosotrosTeAyudamos": "Encuentra tu espacio y resérvalo, nosotros te ayudamos a que el resto sea sencillo y seguro.",
                        "seleccionaTipoPropiedad": "¿Qué tipo de propiedad?",
                        "reservaTuPropiedad": "Reserva",
                        "reservaTuPropiedadBold": "tu casa o departamento,",
                        "reservaTuPropiedadHighlight": "¡desde $5,000!",
                        "monplacesSelections": "Monplaces Selections",
                        "explorarMas": "Explorar Más",
                        "comoFunciona": "¿Cómo funciona?",
                        "comoFuncionaPaso1": "Tomamos referencia y guía de qué es lo que quieres y lo que necesitas en tu próximo espacio.",
                        "comoFuncionaPaso2": "Tenemos comunicación abierta y continua contigo para resolver cualquier duda vía telefónica, WhatsApp o correo electrónico.",
                        "comoFuncionaPaso3": "Si requieres consultar con un asesor en el proceso, respetamos tu tiempo y en 15 minutos te compartimos de toda la información que necesites para que tomes tu decisión.",
                        "comoFuncionaPaso4": "Con tu tarjeta de crédito o débito, ¡aparta desde $5,000!",
                        "comoFuncionaPaso5": "Olvídate de papeleos y copias, reunimos la documentación necesaria de forma digital para simplificar el proceso.",
                        "comoFuncionaPaso6": "De forma presencial o digital, ¡lo que sea mejor para ti!",
                        "comoFuncionaPaso7": "En Monplaces te acompañamos hasta la entrega de tu unidad y mantenemos la comunicación contigo después de esta.",
                        "comoFuncionaPaso1Brief": "Identificamos tus necesidades",
                        "comoFuncionaPaso2Brief": "Te asesoramos por WhatsApp",
                        "comoFuncionaPaso3Brief": "Reuniones virtuales para más detalles",
                        "comoFuncionaPaso4Brief": "¡Aparta tu propiedad!",
                        "comoFuncionaPaso5Brief": "Carga tu documentación en digital",
                        "comoFuncionaPaso6Brief": "Agenda tu firma de contrato",
                        "comoFuncionaPaso7Brief": "¡Listo!",
                        "hablarConExperto": "¿Quieres hablar con un experto?",
                        "hablarConExpertoDescripcion": "En Monplaces nos enfocamos en que realices tu proceso de forma simple, fácil y segura. Si tienes dudas y quieres hablar con un experto, puedes hacerlo",
                        "aqui": "aquí",
                        "encuentraTuEspacio": "Encuentra tu espacio con Monplaces",
                        "creditoHipotecario": "Crédito hipotecario",
                        "creditoHipotecarioDescripcion": "Tenemos a los mejores aliados para que consigas tu crédito hipotecario",
                        "pagoTarjeta": "Pagos con tarjeta",
                        "pagoTarjetaDescripcion": "Para hacerlo más sencillo, paga el apartado de tu propiedad con tu tarjeta. Es fácil, rápido y seguro.",
                        "personalizado": "Personalizado",
                        "personalizadoDescripcion": "¿No encontraste lo que buscabas? ¡Contáctanos y te ayudamos!",
                        "iniciaProceso": "Inicia aquí tu proceso",
                    },
                    DevelopmentList: {
                        "title": "Desarrollos inmobiliarios en venta | Monplaces",
                        "selecciona": "Selecciona las características que estás buscando",
                        "propiedades_one": "1 propiedad en venta en monplaces",
                        "propiedades_other": "{{count}} propiedades en venta en monplaces",
                        "otrasUnidades": "Otras unidades disponibles en este desarrollo",
                        "explorar": "Explorar desarrollo",
                        "desdeEnUbicacion": "Desde {{price}} en {{city}}, {{state}}",
                        "proximamente": "Próximo lanzamiento",
                        "registrate": "Regístrate aquí",
                        "listaCero": "Obtén acceso a la lista de precios de Friends&Family. ¡La etapa para invertir es ahora!",
                        "proyectoVendido": "100% Vendido",
                    },
                    DevelopmentDetails: {
                        "detallesDesarrollo": "Detalles del desarrollo",
                        "tipoUnidades": "Tipos de unidades",
                        "amenidades": "Amenidades y servicios del desarrollo",
                        "disclosure": "*Las características de las unidades y el precio puede variar dependiendo del nivel, vista y/u otros factores. Las unidades y el precio están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.",
                    },
                    UnitDetails: {
                        "detallesUnidad": "Detalles de la unidad",
                        "caracteristicas": "Características generales de la unidad",
                        "amenidades": "Amenidades y servicios del desarrollo",
                        "reserva": "Reserva esta unida con $5,000 MXN",
                        "reembolsable": "100% reembolsable. Aplican TyC.",
                        "disclosure": "*Las unidades, el precio y las políticas comerciales están sujetos a confirmar disponibilidad y última lista de precios actualizada con el desarrollador, por lo que pueden modificarse y/o variar de lo aquí representado.",
                    },
                    Checkout: {
                        "detallesPago": "Detalles del pago",
                        "tuUnidad": "Tu unidad",
                        "amenidades": "Amenidades y servicios del desarrollo",
                        "pagarCon": "Pagar con",
                        "numeroTarjeta": "Número de tarjeta",
                        "codigoSeguridad": "CVV",
                        "expiracion": "Fecha expiración",
                        "politicaCancelacion": "Política de cancelación",
                        "politicaCancelacionDescripcion": "Al apartar tu unidad, ésta queda reservada a tu nombre por 7 días naturales. Conservamos todas las características que escogiste de tipo, nivel y precio mientras tomas la decisión. El apartado es 100% reembolsable dentro de los primeros 7 días naturales (168 hrs.) del proceso de compra.",
                        "masInformacion": "Más información",
                        "aceptoTerminos": "Acepto términos y condiciones",
                        "aceptoPrivacidad": "Acepto políticas de privacidad",
                        "confirmacionPago": "Confirmación de pago",
                        "confirmacionPagoExito": "¡Felicidades, tu pago se ha procesado de forma exitosa!",
                        "confirmacionPagoExistoDescripcion": "Estamos revisando los detalles y pronto te llegará un mail con la confirmación del apartado de tu unidad.",
                        "siguientesPasos": "Para avanzar con el proceso de compra de tu unidad, por favor completa tu documentación.",
                        "confirmacionPagoError": "Tu pago no se ha procesado, por favor intenta nuevamente.",
                        "confirmacionPagoErrorDescripcion": "Si estás teniendo problemas para realizar el pago, ",
                        "confirmacionPagoErrorContacto": "ponte en contacto con nostros.",
                    },
                    Common: {
                        "loading": "Cargando...",
                        "estacionamiento_one": "1 cajón",
                        "estacionamiento_other": "{{count}} cajones",
                        "bano_one": "1 baño",
                        "bano_other": "{{count}} baños",
                        "recamara_one": "1 rec",
                        "recamara_other": "{{count}} rec",
                        "codigo": "Código",
                        "ubicacion": "Ubicación",
                        "tipoPropiedad": "Tipo de propiedad",
                        "tipoOperacion": "Tipo de operación",
                        "antiguedad": "Antigüedad",
                        "precio": "Precio",
                        "superficie": "Superficie en m2",
                        "medida": "Medida",
                        "recamaras": "Recámaras",
                        "nivel": "Nivel",
                        "estacionamientos": "Estacionamientos",
                        "banos": "Baños",
                        "minimo": "Mínimo",
                        "maximo": "Máximo",
                        "precioInicial": "Desde {{price}}",
                        "tamano": "De {{minSize}}m2 a {{maxSize}}m2",
                        "entrega": "Entrega",
                        "inmediata": "Inmediata",
                        "tipo": "Tipo",
                        "descargarBrochure": "Descargar brochure",
                        "descargarPrecios": "Descargar lista de precios",
                        "descargarPoliticas": "Descargar políticas comerciales",
                        "desarrolladora": "Desarrolladora",
                        "mapa": "Mapa",
                        "contactanos": "¿Tienes dudas? Contáctanos por WhatsApp",
                        "unidades_one": "1 unidad disponibles",
                        "unidades_other": "{{count}} unidades disponibles",
                        "seleccione": "Seleccione…",
                        "mostrarFotos": "Mostrar todas las fotos",
                        "total": "Total",
                        "subtotal": "Subtotal",
                        "seleccionaPolitica": "Selecciona la política comercial",
                        "leerMas": "Leer más...",
                        "vendido": "{{percentage}}% vendido",
                        "descuento": "Hasta {{percentage}}% de descuento",
                    },
                    Buttons: {
                        "buscar": "Buscar",
                        "reservar": "Reservar",
                        "registrarse": "Registrarse",
                        "ingresar": "Ingresar",
                        "salir": "Salir",
                        "contactanos": "Contáctanos",
                        "videollamada": "Quiero una video llamada",
                        "cotizacion": "Quiero una cotización",
                        "confirmar": "Confirmar y pagar",
                    },
                    Copy: {
                        "copyright": "Todos los derechos reservados. Prohibida la reproducción total o parcial del contenido de este sitio."
                    },
                    OperationType: {
                        "preventa": "Preventa",
                        "compra": "Compra",
                        "renta": "Renta",
                    },
                    PropertyType: {
                        "departamento": "Departamento",
                        "casa": "Casa",
                        "terreno": "Terreno",
                    },
                    Age: {
                        "preventa": "Preventa",
                        "nuevo": "Nuevo",
                    },
                    Price: {
                        '0-2.5': 'de 0 a 2.5 mdp',
                        '2.5-4': 'de 2.5 a 4 mdp',
                        '4-6': 'de 4 a 6 mdp',
                        '6+': 'más de 6 mdp',
                    }
                },
            },
        },
        lng: 'es', // if you're using a language detector, do not define the lng option
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

export default i18n;