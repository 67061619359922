import React from 'react';
import { Route } from 'react-router-dom';
import auth from '../Auth';

function GuardedRoute(props) {
    const { component: Component, path } = props;

    return (
        <Route exact path={path} render={(props) => {
            if (!auth.isAuthenticated()) {
                return auth.login(props.location.pathname);
            }
            return <Component {...props} />
        }} />
    );
}

export default GuardedRoute;