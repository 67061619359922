
import React from 'react';

import './Modal.css';

const Modal = ({ handleClose, show, children, fullscreen }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className={fullscreen ? "modal-fullscreen" : "modal-main"}>
                {children}
                <button type="button" className='modal-icon' aria-label='Close Modal' onClick={handleClose}>
                    <svg className='modal-svg' viewBox="0 0 87 87" fill="none" stroke="currentColor" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                        <path d="M65.25 21.75L21.75 65.25" />
                        <path d="M21.75 21.75L65.25 65.25" />
                    </svg>
                </button>
            </section>
        </div>
    );
};

export default Modal;