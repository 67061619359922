
import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '../../components/Modal/Modal';
import ImageGalleryVideo from '../../components/ImageGalleryVideo/ImageGalleryVideo';

import './ImageGalleryGrid.css';

const ImageGalleryGrid = ({ mainItem, items, handleClick }) => {
    const { t } = useTranslation();

    const [showImageGallery, setShowImageGallery] = useState(false);
    const [showFullscreenImageGallery, setShowFullscreenImageGallery] = useState(false);

    const [fullscreenIndex, setFullscreenIndex] = useState(0);

    const imageGalleryRef = useRef(null);

    const showModal = () => {
        setShowImageGallery(true)
    }

    const hideModal = () => {
        setShowImageGallery(false)
    }

    const showFullscreenModal = () => {
        setShowFullscreenImageGallery(true)
    }

    const hideFullscreenModal = () => {
        setShowFullscreenImageGallery(false)
    }

    const fullScreen = () => {
        if (imageGalleryRef.current) {
            // imageGalleryRef.current.fullScreen()
            setFullscreenIndex(imageGalleryRef.current.getCurrentIndex())
            showFullscreenModal()
        }
    }

    return (
        <div className='monplaces-image-gallery'>
            <div className='gallery-grid'>
                <div className='main'>
                    {
                        mainItem.type === "video" && mainItem.url ?
                            <div className='video-wrapper' style={{ position: 'relative' }}>
                                <video autoPlay loop muted>
                                    <source src={mainItem.url} type='video/mp4' />
                                </video>
                            </div>
                            :
                            <div onClick={showModal} style={{
                                backgroundImage: `url(${mainItem.url})`,
                                cursor: 'pointer',
                            }} />
                    }
                </div>

                <div onClick={showModal} style={{
                    gridArea: 'one',
                    backgroundImage: `url(${items[0].url})`,
                    cursor: 'pointer',
                }}
                />
                <div onClick={showModal} style={{
                    gridArea: 'two',
                    backgroundImage: `url(${items[1].url})`,
                    cursor: 'pointer',
                }}
                />
                <div onClick={showModal} style={{
                    gridArea: 'three',
                    backgroundImage: `url(${items[2].url})`,
                    cursor: 'pointer',
                }}
                />
                <div onClick={showModal} style={{
                    gridArea: 'four',
                    backgroundImage: `url(${items[3].url})`,
                    cursor: 'pointer',
                }}
                />

                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    <button type='button' className='btn prefix-icon icon-gallery' onClick={showModal} style={{ paddingLeft: '6px', paddingRight: '14px', fontSize: '12px' }}>
                        {t('Common.mostrarFotos')}
                    </button>
                </div>
            </div>

            <Modal show={showImageGallery} handleClose={hideModal}>
                <ImageGalleryVideo
                    items={[mainItem, ...items].map(item => {
                        return {
                            url: item.url,
                            type: item.type,
                            thumbnailUrl: item.thumbnailUrl,
                            thumbnailClass: 'monplaces-image-gallery-grid-thumbnail',
                            originalClass: 'monplaces-image-gallery-grid-original',
                            bulletClass: 'monplaces-image-gallery-grid-bullet',
                        }
                    })}
                    infinite={true}
                    showGalleryFullscreenButton={false}
                    showGalleryPlayButton={true}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={true}
                    showIndex={false}
                    showThumbnails={true}
                    additionalClass={'monplaces-image-gallery-grid'}
                    onClick={handleClick || fullScreen}
                    ref={imageGalleryRef}
                />
            </Modal>

            <Modal show={showFullscreenImageGallery} handleClose={hideFullscreenModal} fullscreen={true}>
                <ImageGalleryVideo
                    items={[mainItem, ...items].map(item => {
                        return {
                            url: item.url,
                            thumbnailUrl: item.thumbnailUrl,
                            type: item.type,
                            originalClass: 'monplaces-image-gallery-grid-original',
                        }
                    })}
                    infinite={true}
                    showGalleryFullscreenButton={false}
                    showGalleryPlayButton={true}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={false}
                    showIndex={true}
                    showThumbnails={false}
                    startIndex={fullscreenIndex}
                    additionalClass={'monplaces-image-fullscreen-grid'}
                />
            </Modal>
        </div>
    );
};

export default ImageGalleryGrid;