import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery, gql } from "@apollo/client";

import { currencyFormat, toKebab } from '../../utils.js';
import '../../App.css';
import './ListOpportunityUnit.css'

const LIST_OPPORTUNITY_UNIT = gql`
  query GetOpportunityUnit($operationType: String, $propertyType: String, $city: String, $isPublic: Boolean) {
    units(operation_type: $operationType, property_type: $propertyType, city: $city, is_public: $isPublic) {
      id
      code
      number
      floor
      gallery_urls {
        url
        thumbnailUrl
        type
        tag
      }
      bedrooms
      bathrooms
      parking_spots
      price
      currency
      size
      status
      operationType
      is_public
      is_opportunity
      unitType {
        type
      }
      group {
        name
        display_name
        address {
          city
        }
      }
    }
  }
`

const ListOpportunityUnit = (props) => {
  const operationType = props.match.params.operation ? props.match.params.operation.toLowerCase() : "";
  const propertyType = props.match.params.type ? props.match.params.type.toLowerCase() : "";
  const city = props.match.params.city ? props.match.params.city.toLowerCase() : "";

  const { loading, data, error } = useQuery(LIST_OPPORTUNITY_UNIT, {
    variables: {
      operationType: operationType,
      propertyType: propertyType,
      city: city,
      isPublic: false
    }
  })

  const [units, setUnits] = useState(null);

  useEffect(() => {
    if (data) {
      const unitsFiltered = data.units.filter(unit => unit.status === 'Disponible');

      setUnits(unitsFiltered)
    }
  }, [loading, data]);

  if (loading) return <div className="container-fluid"><div className='row'>Loading...</div></div>;
  if (error) return <div className="container-fluid"><div className='row'>Error! {error.message}</div></div>;

  return (
    <>
      <Helmet>
        <title>Propiedades</title>
        <meta
          name="description"
          content="¿Buscas casas ó departamentos en venta?. Encuentra las mejores oportunidades en México que tenemos para ti."
        />
        <link rel='canonical' href='https://www.monplaces.com/properties' />
      </Helmet>
      <div className="container-fluid">
        {units &&
          <div className='units-opportunity'>
            {units.length === 1 &&
              <p>1 propiedad en Monplaces</p>
            }
            {units.length !== 1 &&
              <p>{units.length} propiedades en Monplaces</p>
            }
            <div className='cards-3'>
              {units.map(unit => (
                <Link className='card-item' key={unit.id} to={`/properties/${toKebab(unit.operationType)}/${toKebab(unit.unitType.type)}/${toKebab(unit.group.address.city)}/${unit.code}`}>
                  <div className='image'>
                    <p className='title'>Código: {unit.code}</p>

                    <img src={unit.gallery_urls.filter(i => i.tag === 'layout')[0].url} alt={unit.code} style={{ position: 'center' }}></img>

                    {unit.is_opportunity &&
                      <label className="badge">Oportunidad</label>
                    }
                  </div>
                  <div className='info outer'>
                    <div className='middle'>
                      <p className="subtitle">{currencyFormat(unit.price, unit.currency)}</p>
                      <p className="prefix-icon icon-land-size">{unit.size} m2</p>
                      <p className="prefix-icon icon-bedroom">{unit.bedrooms} rec</p>
                      {unit.bathrooms === 1 &&
                        <p className="prefix-icon icon-bathroom">1 baño</p>
                      }
                      {unit.bathrooms !== 1 &&
                        <p className="prefix-icon icon-bathroom">{unit.bathrooms} baños</p>
                      }
                      {unit.parking_spots === 1 &&
                        <p className="prefix-icon icon-car nowrap">1 cajón</p>
                      }
                      {unit.parking_spots !== 1 &&
                        <p className="prefix-icon icon-car nowrap">{unit.parking_spots} cajones</p>
                      }
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default withRouter(ListOpportunityUnit);