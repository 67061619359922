
import React, { useState, useRef } from 'react';

import Modal from '../../components/Modal/Modal';
import ImageGalleryVideo from '../../components/ImageGalleryVideo/ImageGalleryVideo';

import './ImageGallerySimple.css';

const ImageGallerySimple = ({ items, handleClick }) => {
    const [showFullscreenImageGallery, setShowFullscreenImageGallery] = useState(false);

    const [fullscreenIndex, setFullscreenIndex] = useState(0);

    const imageGalleryRef = useRef(null);

    const showFullscreenModal = () => {
        setShowFullscreenImageGallery(true)
    }

    const hideFullscreenModal = () => {
        setShowFullscreenImageGallery(false)
    }

    const fullScreen = () => {
        if (imageGalleryRef.current) {
            // imageGalleryRef.current.fullScreen()
            setFullscreenIndex(imageGalleryRef.current.getCurrentIndex())
            showFullscreenModal()
        }
    }

    return (
        <div className='monplaces-image-gallery'>
            <ImageGalleryVideo
                items={items.map(item => {
                    return {
                        url: item.url,
                        thumbnailUrl: item.thumbnailUrl,
                        type: item.type,
                        originalClass: 'monplaces-image-gallery-simple-original',
                        bulletClass: 'monplaces-image-gallery-simple-bullet'
                    }
                })}
                showIndex={false}
                infinite={true}
                showGalleryFullscreenButton={false}
                showGalleryPlayButton={true}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={items.length > 1}
                showThumbnails={false}
                additionalClass={'monplaces-image-gallery-simple'}
                onClick={handleClick || fullScreen}
                ref={imageGalleryRef}
            />

            <Modal show={showFullscreenImageGallery} handleClose={hideFullscreenModal} fullscreen={true}>
                <ImageGalleryVideo
                    items={items.map(item => {
                        return {
                            url: item.url,
                            thumbnailUrl: item.thumbnailUrl,
                            type: item.type,
                            originalClass: 'monplaces-image-gallery-fullscreen-original'
                        }
                    })}
                    showIndex={true}
                    infinite={true}
                    showGalleryFullscreenButton={false}
                    showGalleryPlayButton={true}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={false}
                    startIndex={fullscreenIndex}
                    additionalClass={'monplaces-image-fullscreen-simple'}
                />
            </Modal>
        </div >
    );
};

export default ImageGallerySimple;